const Services = () => {

return(
    <div class="services">
        <h1 class="servicesHeader">We Tile, You Smile.</h1>
        <div class="servicesDesc">
            <p>Serving New Jersey, Queens, Brooklyn, & Staten Island for 25+ Years</p>
            <p>Custom Tile Installation, Demolition, Repairs, & Mudjobs</p>
            <p>Licensed & Insured</p>
        </div>
    </div>
    )
}

export default Services;